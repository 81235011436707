.login img {
  width: 80px;
  height: 80px;
}

.login ion-row {
  --ion-padding: 50px;
}

.verified {
  font-size: 12px;
  color: green;
  margin: 5px 15px;
}

.card {
  max-width: 600px;
  margin: 100px auto; 
}

.card ion-card-content {
  padding: 30px 50px
}