.shop02-blog ion-title{
  font-size: medium;
}

.shop02-blog .shop02-video {
  width: 100%;
  height: 60vw;
}

.shop02-blog .shop02-video iframe{
  width: 100%;
  height: 60vw;
}

.shop02-blog .image-count {
  position: absolute;
  left: 100%;
  top: 100%;
  transform: translate(-150%, -150%);
  background-color: rgba(0,0,0,0.25);
  color: var(--ion-color-primary);
  padding: 3px 3px;
  border-radius: 8px;
  font-size: small;
}

.shop02-blog p {
  white-space: pre-line;
}

.shop02-blog .transparent-header {
  position: absolute;
}

.shop02-blog .transparent-header ion-toolbar {
  --background: transparent;
  --ion-color-base: transparent !important;
}

.shop02-blog .transparent-header ion-button {
  --background: rgba(0,0,0,0.25);
}

.shop02-blog .shop02-description h1 {
  /* font-size: 22px; */
  font-size: 1.375em;
}

.shop02-blog .shop02-description h2 {
  /* font-size: 18px; */
  font-size: 1.125em;
}

.shop02-blog .shop02-description h3 {
  /* font-size: 16px; */
  font-size: 1em;
} 

.shop02-blog .shop02-description p {
  /* font-size: 14px; */
  font-size: 0.875em;
}

.shop02-blog .shop02-product ion-img {
  width: 80px;
  height: 80px;
}

.shop02-blog .shop02-product h2,
.shop02-blog .shop02-product h3,
.shop02-blog .shop02-product p {
  white-space: normal;
}

.shop02-blog ion-grid.shop02-product,
.shop02-blog .shop02-product ion-col {
  padding: 5px 0;
}

.shop02-blog .shop02-product .out-of-stock {
  font-size: 12px;
  color: var(--ion-color-danger) !important;
}
