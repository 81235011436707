.setting ion-card-subtitle {
    width: 10%;
    text-align: end;
}

.setting ion-row {
  padding: 15px 0;
  align-items: center;
}

.setting p {
    width: 80%;
    /* color: black; */
    white-space: normal;
}