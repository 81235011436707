.shop02-details ion-title{
  font-size: medium;
}

.shop02-details ion-grid,
.shop02-details ion-col {
  padding: 0 0;
}

.shop02-details .image-count {
  position: absolute;
  left: 100%;
  top: 100%;
  transform: translate(-150%, -150%);
  background-color: rgba(0,0,0,0.25);
  color: var(--ion-color-primary);
  padding: 3px 3px;
  border-radius: 8px;
}

.shop02-details p {
  white-space: pre-line;
}

.shop02-details .transparent-header {
  position: absolute;
}

.shop02-details .transparent-header ion-toolbar {
  --background: transparent;
  --ion-color-base: transparent !important;
}

.shop02-details .transparent-header ion-button {
  --background: rgba(0,0,0,0.25);
}
