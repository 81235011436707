.shop02-home ion-card {
  margin: 5px 5px;
}

.shop02-home ion-card-header {
  padding: 5px 16px;
}

.shop02-home ion-card-title {
  font-size: 1em;
}

.shop02-home ion-card-subtitle {
  font-size: 0.875em;
  margin: 4px 0;
}