.shop02-history-details ion-img {
  width: 80px;
  height: 80px;
}

.shop02-history-details h2,
.shop02-history-details h3,
.shop02-history-details p {
  white-space: normal;
}
