/* max-width is set to same as height px, for more mobile feel experience can set to 70vh */
/* .app-aspect-lock-container ion-grid, ion-toolbar { */
.app-aspect-lock-container {
  max-width: 1200px;
  margin: 0 auto; 
}

/* for IOS to display segment properly */
ion-segment-button {
  min-width: fit-content;
}
/* ion-segment ::-webkit-scrollbar, *::-webkit-scrollbar { display: none; } */

.ion-item-full-width {
  --padding-start: 0;
  --inner-padding-end: 0;
}

/* ======================== Horizontal + Verticle center align method ======================== */
/* -container: apply to parent     -item: apply to target item */

/* ---------- flex box method ---------- */
.hv-center-flex-container {
  display: flex;
  align-items: center;      /* align is for verticle */
  justify-content: center;  /* justify is for horizontal */
}

/* ---------- absolute position method ---------- */
.hv-center-absolute-item {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* ======================== Verticle center align method ======================== */
/* -container: apply to parent     -item: apply to target item */

/* ---------- flex box method ---------- */
.v-center-flex-container {
  display: flex;
  align-items: center;
}

/* ---------- absolute position method ---------- */
.v-center-absolute-item {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

/*
below methods using fix pixel control, so copy and use in individual css
---------- line height method ----------
.v-center-lineheight-container {
  line-height: 100px;
  height: 100px;
}

.v-center-lineheight-item {
  display: inline-block;
  vertical-align: middle;
}

---------- padding method ----------
.v-center-padding-container {
  padding: 100px 0;
}
*/

/* ======================== Verticle bottom align method ======================== */
/* -container: apply to parent     -item: apply to target item */

/* ---------- flex box method ---------- */
.v-end-flex-container {
  display: flex;
  align-items: flex-end;
}

/* ---------- absolute position method ---------- */
.v-end-absolute-item {
  position: absolute;
  bottom: 0px;
}

/* ======================== Horizontal center align method ======================== */
/* -container: apply to parent     -item: apply to target item */

/* ---------- text align method ---------- */
.h-center-align-container {
  text-align: center;
}

.h-center-align-item {
  display: inline-block;
}

/* ---------- margin auto method ---------- */
.h-center-margin-item {
  display: block;
  margin: auto;
/*  
  margin-left: auto;
  margin-right: auto;
*/  
}

/* ---------- flex box method ---------- */
.h-center-flex-container {
  display: flex;
  justify-content: center;  /* justify is for horizontal */
}

/* ---------- absolute position method ---------- */
/* drawback: this will cause the item remove from the flow and parent size would not expand according to item */
.h-center-absolute-item {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}

/* ======================== Horizontal right align method ======================== */
/* -container: apply to parent     -item: apply to target item */

/* ---------- text align method ---------- */
.h-right-align-container {
  text-align: right;
}

.h-right-align-item {
  display: inline-block;
}

/* ---------- margin auto method ---------- */
.h-right-margin-item {
  margin-left: auto;
}

/* ---------- float method ---------- */
/* optional might need to apply to the parent container if item is img */
.h-right-float-container {
  overflow: auto;
}

.h-right-float-item {
  float: right;
}

/* ---------- absolute position method ---------- */
/* drawback: this will cause the item remove from the flow and parent size would not expand according to item */
.h-right-absolute-item {
  position: absolute;
  right: 0px;
}

/* ========================================================================= */

/*
.test-parent {
  overflow: auto;
}

.test-container {
  position: relative;
  height: 100%;
  width: 100%;
  border-color: red;
  border-width: 1px;
  border-style: solid;

}
*/

.test-item {
  border-color: red;
  border-width: 1px;
  border-style: solid;
}

.test-item1 {
  border-color: green;
  border-width: 1px;
  border-style: solid;
}
