.shop01-cart ion-list {
  box-sizing: border-box;
}

.shop01-cart ion-toggle {
  --background: lightGray;
  --background-checked: lightGray;

  --handle-background: #3880ff;
  --handle-background-checked: #3880ff;
}

.shop01-cart .cart-empty {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.shop01-cart .cart-product img {
  width: 80px;
  height: 80px;
}

.shop01-cart .cart-product h2,
.shop01-cart .cart-product h3,
.shop01-cart .cart-product p {
  white-space: normal;
}

.shop01-cart .cart-product .notes {
  font-size: 12px;
}

.shop01-cart .cart-product ion-grid,
.shop01-cart .cart-product ion-col {
  padding: 0 0;
}

.shop01-cart .cart-product-qty ion-text {
  vertical-align: -webkit-baseline-middle;
}

.shop01-cart .cart-product-price {
  text-align: right;
}