.shop01-listing ion-img {
  width: 80px;
  height: 80px;
}

.shop01-listing h2,
.shop01-listing h3,
.shop01-listing p {
  white-space: normal;
}

.shop01-listing ion-grid,
.shop01-listing ion-col {
  padding: 5px 0;
}

.shop01-listing .out-of-stock {
  font-size: 12px;
  color: var(--ion-color-danger) !important;
}
