.order-details ion-img {
  width: 80px;
  height: 80px;
}

.order-details h2,
.order-details h3,
.order-details p {
  white-space: normal;
}

/*
.order-details p {
  font-size: 0.8rem;
  margin: 5px 0;
  padding: 0 0;
}
*/
