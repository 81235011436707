.shop02-listing ion-img {
  width: 80px;
  height: 80px;
}

.shop02-listing h2,
.shop02-listing h3,
.shop02-listing p {
  white-space: normal;
  /* font-size: smaller; */
}

/* .shop02-listing ion-label {
  font-size: smaller !important;
} */

.shop02-listing ion-grid,
.shop02-listing ion-col {
  /* padding: 1px 0; */
  padding: 5px 0;
}

.shop02-listing .out-of-stock {
  font-size: 12px;
  color: var(--ion-color-danger) !important;
}
