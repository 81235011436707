/* .mobile-ion-slides {
  height: 100vw;
  width: 100vw;
} */

/* .desktop-ion-slides {
  height: 450px;
  width: 450px;
  margin: 0 0 16px 0;
} */
/*
.row {
  width: 100%;
}

.shop01-details ion-img {
  width: 100%; 
}

.shop01-details h5 {
  margin-top: 0;
}
*/

.shop01-details .image-count {
  position: absolute;
  left: 100%;
  top: 100%;
  transform: translate(-150%, -150%);
  background-color: rgba(0,0,0,0.25);
  color: var(--ion-color-primary);
  padding: 3px 3px;
  border-radius: 8px;
}

.shop01-details p {
  white-space: pre-line;
}

.shop01-details .transparent-header {
  position: absolute;
}

.shop01-details .transparent-header ion-toolbar {
  --background: transparent;
  --ion-color-base: transparent !important;
}

.shop01-details .transparent-header ion-button {
  --background: rgba(0,0,0,0.25);
}