
.FormRow {
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 15px 0 30px;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElementFpx,
.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

.fpx-img {
  height: 30px;
}
