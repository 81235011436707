.form-container {
  flex: 1;
  height: 100%
}

.error-message {
  font-size: 12px;
  color: red;
  margin: 5px 15px;
}

.submit-button {
  position: absolute;
  bottom: 0;
  margin: 10px;
  width: -webkit-fill-available;
}