.payment-status ion-row {
  --ion-padding: 50px;
}

.payment-status ion-icon {
  font-size: 100px;
}

.payment-status ion-text {
  white-space: none;
}