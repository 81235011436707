.shop02-history ion-title{
  font-size: medium;
}

.shop02-history ion-text {
  font-size: 0.75rem;
}

.shop02-history ion-col {
  padding: 2px 2px;
}
