.shop02-favourite ion-img {
  width: 80px;
  height: 80px;
}

.shop02-favourite h2,
.shop02-favourite h3,
.shop02-favourite p {
  white-space: normal;
}

.shop02-favourite ion-grid,
.shop02-favourite ion-col {
  padding: 5px 0;
}

.shop02-favourite .out-of-stock {
  font-size: 12px;
  color: var(--ion-color-danger) !important;
}
